import React, { useEffect, useState } from 'react';
import './MultiSelect.scss';
import Input from '../input/Input';

interface MultiSelectProps {
    id: string;
    onChange: (arg: Array<any>) => void;
    disabled?: boolean;
    customClass?: string;
    buttonCustomClass?: string;
    placeholder?: string;
    value?: Array<string>;
    selectedValue?: Array<any>;
    enableSearch?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = (props) => {

    const [selectedOptions, setSelectedOptions] = useState<Array<any>>([]);
    const [searchState, setSearchState] = useState('');

    const handleOptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: any) => {
        event.stopPropagation();
        let array: Array<string> = props.selectedValue ? [...props.selectedValue] : [];
        if (selectedOptions.includes(value)) {
            array = array.filter(option => option !== value);
        } else {
            array.push(value);
        }
        props.onChange(array);
        setSelectedOptions([...array]);
    };

    useEffect(() => {
        setSelectedOptions([...props.selectedValue || []]);
    }, [props.selectedValue])

    return (
        <>
            <div className={"multiSelectCommon " + (props.customClass ? props.customClass : "")}>
                <button
                    type="button"
                    className={"buttonCommonMulti w-100 " + (props.buttonCustomClass ? props.buttonCustomClass : "")}
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {props.placeholder}
                </button>
                <ul className="dropdown-menu dropdownMultiSelect">
                    {
                        props.value && props.value.length > 0 &&
                        <>
                            {props.enableSearch && <>
                                <Input
                                    id="searchFilters"
                                    customClass="searchFilters border-white w-auto"
                                    placeholder="Search..."
                                    onChange={(str) => { setSearchState(str) }}
                                />
                            </>}
                            {props.value?.filter((e) => {
                                if (searchState !== "") {
                                    return e.toString().toLowerCase().includes(searchState.toLowerCase());
                                }
                                return e
                            }).map((e, i) => {
                                return <>
                                    <li key={e + i}>
                                        <div
                                            className={`color-white ps-2`}
                                            onClick={(event) => handleOptionClick(event, e)}
                                        >
                                            <i className={`fa-regular ${selectedOptions.includes(e) ? 'fa-check' : 'fa-square'} `}></i>
                                            <span className='ps-2'>{e}</span>
                                        </div>
                                    </li>
                                </>
                            })}
                        </>

                    }
                    {
                        !props.value && <li key={'empty'}>
                            <div
                                className={`color-white ps-2`}
                            >
                                <i className={`fa-regular fa-xmark`}></i>
                                <span className='ps-2'>Empty</span>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </>
    );
}


export default MultiSelect;
