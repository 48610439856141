import { isAdmin } from './commonUtils';

export interface FiltersInterface {
  name: string;
  label: string;
  value: boolean;
  col: number;
  noArr?: boolean;
  lastSelect?: boolean;
  adminOnly?: boolean;
}

export default class FiltersUtils {
  inventory: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: false, col: 2 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'price', label: 'Price', value: false, col: 1 },
    { name: 'buy_date', label: 'Buy Date', value: true, lastSelect: true, col: 1 },
    { name: 'buy_store', label: 'Buy Store', value: false, col: 1 },
    { name: 'possible_payout', label: 'Possible Payout', value: false, col: 1 },
    { name: 'possible_profit', label: 'Possible Profit', value: false, col: 1 },
    { name: 'listing_status', label: 'Listing Status', value: false, col: 1 },
  ];

  shared_inventory: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: true, lastSelect: true, col: 2 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: '', label: '', value: false, col: 8 },
  ];

  wtbs: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: true, lastSelect: true, col: 2 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'price', label: 'Price', value: false, col: 1 },
    { name: '', label: '', value: false, col: 7 },
  ];

  sell: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: true, lastSelect: true, col: 2 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'price', label: 'Price', value: false, col: 1 },
    { name: 'country', label: 'Country', value: false, col: 1, noArr: true },
    { name: 'paymethods', label: 'Payment Method', value: false, col: 2, noArr: true },
    { name: 'owner', label: 'Owner', value: false, col: 2, adminOnly: true, noArr: true },
  ];

  adminInventory: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: false, col: 3 },
    { name: 'ownerEmail', label: 'Owner Email', value: false, col: 3 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'price', label: 'Price', value: false, col: 1 },
    { name: 'buy_date', label: 'Buy Date', value: true, lastSelect: true, col: 1 },
    { name: 'buy_store', label: 'Buy Store', value: false, col: 2 },
  ];

  listed: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: true, col: 2, lastSelect: true },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'marketplace', label: 'Marketplace', value: false, col: 2 },
    { name: 'possible_payout', label: 'Possible Payout', value: false, col: 1 },
    { name: 'possible_profit', label: 'Possible Profit', value: false, col: 1 },
    { name: 'listing_status', label: 'Listing Status', value: false, col: 2 },
  ];

  sold: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: false, col: 2 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'price', label: 'Price', value: false, col: 1 },
    { name: 'buy_date', label: 'Buy Date', value: false, col: 1 },
    { name: 'buy_store', label: 'Buy Store', value: false, col: 1 },
    { name: 'sale_price', label: 'Sale Price', value: false, col: 1 },
    { name: 'sale_date', label: 'Sale Date', value: true, col: 1, lastSelect: true },
    { name: 'sale_store', label: 'Sale Store', value: false, col: 1 },
    { name: 'profit', label: 'Profit', value: false, col: 2 },
  ];

  income_expenses: Array<FiltersInterface> = [
    { name: 'description', label: 'Description', value: false, col: 3 },
    { name: 'service', label: 'Service', value: false, col: 2 },
    { name: 'income_expense', label: 'Income/Expense', value: false, col: 2 },
    { name: 'spent_earned', label: 'Spent/Earned', value: false, col: 2 },
    { name: 'date', label: 'Date', value: true, col: 3, lastSelect: true },
  ];

  income_expenses_periodic: Array<FiltersInterface> = [
    { name: 'description', label: 'Description', value: false, col: 3 },
    { name: 'service', label: 'Service', value: false, col: 2 },
    { name: 'price', label: 'Price', value: false, col: 2 },
    { name: 'begin_date', label: 'Begin Date', value: false, col: 2, lastSelect: true },
    { name: 'frequency', label: 'Frequency', value: true, col: 3 },
  ];

  shippings: Array<FiltersInterface> = [
    { name: 'product_name', label: 'Product Name', value: false, col: 2 },
    { name: 'size', label: 'Size', value: false, col: 1 },
    { name: 'sku', label: 'Sku', value: false, col: 1 },
    { name: 'sale_price', label: 'Sale Price', value: false, col: 1 },
    { name: 'sale_date', label: 'Sale Date', value: true, col: 1, lastSelect: true },
    { name: 'sale_store', label: 'Sale Store', value: false, col: 1 },
    { name: 'courier', label: 'Courier', value: false, col: 1 },
    { name: 'tracking', label: 'Tracking', value: false, col: 1 },
    { name: 'status', label: 'Status', value: false, col: 1 },
    { name: 'label', label: 'Label', value: false, col: 1, noArr: true },
  ];

  public getFilters(name: string): Array<FiltersInterface> {
    switch (name) {
      case 'inventory': {
        return this.inventory;
      }
      case 'sharedInventory': {
        return this.shared_inventory;
      }
      case 'wtbs': {
        return this.wtbs;
      }
      case 'sell': {
        return this.sell;
      }
      case 'adminInventory': {
        return this.adminInventory;
      }
      case 'listed': {
        return this.listed;
      }
      case 'sold': {
        return this.sold;
      }
      case 'incomes_expenses': {
        return this.income_expenses;
      }
      case 'incomes_expenses_periodic': {
        return this.income_expenses_periodic;
      }
      case 'shippings': {
        return this.shippings;
      }
      default:
        return [];
    }
  }

  public static initializeFilters(name: string) {
    let filtersUtils = new FiltersUtils();
    let sortFilters = filtersUtils.getFilters(name);
    if (!isAdmin()) {
      sortFilters = sortFilters.filter((_e) => _e.adminOnly === false || _e.adminOnly === undefined);
    }
    switch (name) {
      case 'inventory':
        return localStorage.getItem('filtersInventory') ? JSON.parse(localStorage.getItem('filtersInventory')!) : sortFilters;
      case 'sharedInventory':
        return localStorage.getItem('filtersSharedInventory') ? JSON.parse(localStorage.getItem('filtersSharedInventory')!) : sortFilters;
      case 'wtbs':
        return localStorage.getItem('filtersWtbs') ? JSON.parse(localStorage.getItem('filtersWtbs')!) : sortFilters;
      case 'sell':
        return localStorage.getItem('filtersSell') ? JSON.parse(localStorage.getItem('filtersSell')!) : sortFilters;
      case 'adminInventory':
        return localStorage.getItem('filtersAdminInventory') ? JSON.parse(localStorage.getItem('filtersAdminInventory')!) : sortFilters;
      case 'shippings':
        return localStorage.getItem('filtersShippings') ? JSON.parse(localStorage.getItem('filtersShippings')!) : sortFilters;
      case 'sold':
        return localStorage.getItem('filtersSold') ? JSON.parse(localStorage.getItem('filtersSold')!) : sortFilters;
      case 'listed':
        return localStorage.getItem('filtersListings') ? JSON.parse(localStorage.getItem('filtersListings')!) : sortFilters;
      case 'incomes_expenses':
        return localStorage.getItem('filtersIncomeExpenses') ? JSON.parse(localStorage.getItem('filtersIncomeExpenses')!) : sortFilters;
      case 'incomes_expenses_periodic':
        return localStorage.getItem('filtersIncomeExpensesPeriodic') ? JSON.parse(localStorage.getItem('filtersIncomeExpensesPeriodic')!) : sortFilters;
    }
  }

  public static initializeItemPerPage() {
    return localStorage.getItem('itemPerPage') ? Number(localStorage.getItem('itemPerPage')!) : 15;
  }

  public static resetFilters(): void {
    localStorage.removeItem('filtersInventory');
  }
}
